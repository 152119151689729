/* globals $ App */
import Dropzone from 'dropzone'
import { get } from "@rails/request.js"

Dropzone.autoDiscover = false // disable the built-in autodiscovery
Dropzone.options.quoteFileUpload = {
  paramName: 'quote[file]', // The name that will be used to transfer the file
  init () {
    this.on('queuecomplete', function (file) {
      // this has to work for broker context as well, so use current location
      // const url = `/broker/quotes/${$('form').data('id')}/files`
      const url = window.location.href
      get(url, { responseKind: "turbo-stream" })
    })
  }
}

class QuoteUsage {
  static calculateAnnualUsage () {
    var sum = $("input[id^='quote_quote_monthly_usage_attributes_month']")
      .get()
      .reduce((res, elm) =>
        (res + (+elm.value || 0)), 0)

    document.getElementById("quote_usage_annual").value = sum
  }
}

class BrokerContact {
  static findContacts () {
    const brokerId = $('select#quote_broker_id').val()
    const callback = response => this.populateBrokerContacts(response)

    if (!brokerId) {
      this.populateBrokerContacts([])
      return
    }

    return $.get(`/brokers/${brokerId}/broker_contacts.json`, null, callback, 'json')
  }

  static populateBrokerContacts (contacts) {
    const contactList = $('select[id=quote_broker_contact_id]')
    contactList.empty()

    const ccContactList = $('select[id=quote_cc_broker_contact_ids]')
    ccContactList.empty()

    contactList.append('<option value="">Please select</option>')
    contacts.map(function (contact) {
      ccContactList.append($('<option></option>').attr('value', contact.id).text(contact.full_name))

      const option = $('<option></option>').attr('value', contact.id).text(contact.full_name)
      const parentContactId = $('select[id=quote_broker_contact_id]').data('parent-contact-id')
      if (contact.is_default && !parentContactId) { option.attr('selected', true) }
      if (parentContactId === contact.id) { option.attr('selected', true) }
      contactList.append(option)
    })
  }
}

document.addEventListener('triggerJQuery', function () {
  if (!($('.quotes, .quote_files, .quote_locations').length > 0)) { return }

  Dropzone.discover() // init dropzone after turbolinks load

  $('#show_quote_filters').click(function () {
    $('#quote_filters').slideDown('fast')
    $(this).hide()
    return false
  })

  $('.quote_status').click(function () {
    $('input[name="status_select"]').val($(this).attr('id'))
    $('form').submit()
    return false
  })

  $('#clone_link').click(function () {
    $('input[name="clone_quote"]').val($(this).attr('id'))
    $('form').submit()
    return false
  })

  $('.use_contact_address').click(function () {
    $('#quote_bill_street').val($('#quote_address_street').val())
    $('#quote_bill_city').val($('#quote_address_city').val())
    $('#quote_bill_state').val($('#quote_address_state').val())
    $('#quote_bill_zip').val($('#quote_address_zip').val())
    return false
  })

  $('#quote_broker_id').change(function () {
    BrokerContact.findContacts()

    if (!$(this).val()) {
      $('input#quote_broker_fee').val('')
      $('.quote_broker_fields').hide()
    } else {
      $('.quote_broker_fields').show()
    }
  })

  $('#broker_contact_add_link').click(function () {
    let brokerId = $('#quote_broker_id').val()
    $(this).attr('href', `/brokers/${brokerId}/broker_contacts/`)
  })

  $('#quote_utility_id').change(function () {
    const id = parseInt($(this).val())
    const utility = $('#quote_utility_id').data('units').filter(utility => utility.id === id)

    if (utility.length > 0) {
      $('.quote_units').text(utility[0].units)

      $('#quote_bill_method').empty()
      for (let key in utility[0].bill_methods) {
        const text = utility[0].bill_methods[key]
        $('#quote_bill_method').append($('<option>', { value: key, text }))
      }
    }
  })

  $('#show_billing_section input:checkbox').change(function () {
    if ($(this)[0].checked) {
      $('#show_billing_section').hide()
      $('#billing_section').slideDown('fast')
    }
  })

  $('#quote_parent_id').change(function () {
    const parentId = $(this).val()
    if (!parentId) { return }

    const callback = function (response) {
      $('#quote_customer_company').val(response.customer_company)
      $('#quote_customer_contact').val(response.customer_contact)
      $('#quote_customer_email').val(response.customer_email)
      $('#quote_customer_phone').val(response.customer_phone)
      $('#quote_address_street').val(response.address_street)
      $('#quote_address_city').val(response.address_city)
      $('#quote_address_state').val(response.address_state)
      $('#quote_address_zip').val(response.address_zip)
      $('#quote_bill_street').val(response.bill_street)
      $('#quote_bill_city').val(response.bill_city)
      $('#quote_bill_state').val(response.bill_state)
      $('#quote_bill_zip').val(response.bill_zip)
      $('#quote_broker_id').val(response.broker_id)
      $('select[id=quote_broker_contact_id]').data('parent-contact-id', response.broker_contact_id) // save contact Id to be selected
      $('#quote_broker_id').change() // call change event to show broker fields
      $('#quote_broker_fee').val(response.broker_fee)
      if (!!response.bill_street) {
        $('#show_billing_section input:checkbox').prop('checked', true)
        $('#show_billing_section input:checkbox').change()
      }
    }
    $.get(`/quotes/${parentId}/customer_data.json`, null, callback, 'json')
  })

  $('#quote_usage_type_monthly').change(function() {
    if ($(this).prop('checked')) {
      document.getElementById("quote_usage_annual").readOnly = true
      $('#usage_monthly_control').show()
    } else {
      document.getElementById("quote_usage_annual").readOnly = false
      $('#usage_monthly_control').hide()
    }
  })

  $('input[id^="quote_quote_monthly_usage_attributes_month"]').change(function() {
    QuoteUsage.calculateAnnualUsage()
  })

})
